import { inflate } from "pako";

export function unzip(base64str: string) {
  const strData = atob(base64str);

  const charData = strData.split("").map((x) => {
    return x.charCodeAt(0);
  });

  const binData = new Uint8Array(charData);

  return JSON.parse(inflate(binData, { to: "string" }));
}
