import axios from "axios";
import store from "../store/index";
import { EventBus } from "quasar";
import router from "../router";

//https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec

const baseUrl = import.meta.env.VITE_API_URL;
const Api_Path = `${baseUrl}/`;
const bus = new EventBus();

const httpClient = axios.create({
  baseURL: Api_Path,
  headers: {
    "Content-Type": "application/json",
  },
});

const authInterceptor = (config) => {
  if (!config.headers["Authorization"]) {
    if (store.getters.accessToken) {
      config.headers["Authorization"] = `Bearer ${store.getters.accessToken}`;
    } else {
      const localStorageAccessToken = localStorage.getItem("accessToken");
      if (localStorageAccessToken) {
        config.headers["Authorization"] = `Bearer ${localStorageAccessToken}`;
      }
    }
    if (store.getters.compassSession) {
      config.headers["COMPASS_SESSION"] = store.getters.compassSession;
    } else {
      config.headers["COMPASS_SESSION"] = localStorage.getItem("compassSession");
    }
  }
  return config;
};
const errorInterceptor = (error) => {
  if (error.response && error.response.fromCache) {
    return Promise.resolve(error.response);
  }
  // check if it's a server error
  if (!error.response) {
    //notify.warn('Network/Server error');
    console.error("**Network/Server error");
    console.log(error.response);
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {
    case 400:
      console.error(error.response.status, error.message);
      //notify.warn('Nothing to display', 'Data Not Found');
      break;

    case 401: // authentication error, logout the user
      //notify.warn('Please login again', 'Session Expired');
      console.error("Logging out...", error.response.status, error.message);
      store.dispatch("logOut", router).finally(() => {
        bus.emit("logout");
      });
      break;

    default:
      console.error(error.response.status, error.message);
    //notify.error('Server Error');
  }

  return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      // yay!
      break;
    // any other cases
    default:
    // default case
  }

  return response;
};

// Routes we don't want to cache
const routesToInvalidate = ["capacity", "task"];
// Routes we always want to cache and don't delete
const routesToPersist = ["tdapi/user", "tdapi/roles", "tdapi/companies", "starlink/priceplans"];
// Routes (POST, PUT OR PATCH) that will not trigger the deletion of localStorage cache
const shouldInvalidateCache = (url) => routesToInvalidate.some((route) => url.includes(route));
const shouldPersistCache = (key) => routesToPersist.some((route) => key.includes(route));
const shouldIgnoreRoute = (url) => routesToIgnore.some((route) => url.includes(route));
const getCacheKey = (config) => `${config.method}:${config.url}`;
const isCacheEnabled = import.meta.env.VITE_CACHE_LOCAL === "true";

// Cache Request Interceptor
const cacheRequestInterceptor = (config) => {
  if (config.method.toLowerCase() === "get" && !shouldInvalidateCache(config.url)) {
    const cacheKey = getCacheKey(config);
    const cachedResponse = localStorage.getItem(cacheKey);
    if (cachedResponse) {
      const cachedData = JSON.parse(cachedResponse);
      throw { response: { ...config, data: cachedData, status: 200, fromCache: true } };
    }
  }
  return config;
};

const cacheResponseInterceptor = (response) => {
  console.log("checking cache response");
  console.log(response);
  if (
    ["post", "put", "patch"].includes(response.config.method.toLowerCase()) &&
    !shouldIgnoreRoute(response.config.url)
  ) {
    // Invalidate cache for all related GET requests after a POST

    // Uncomment below if you need to delete all keys after a POST, PUT or PATCH request

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("get:") && !shouldPersistCache(key)) {
        localStorage.removeItem(key);
      }
    });
  } else if (!response.fromCache && response.config.method.toLowerCase() === "get") {
    // Cache new GET responses unless they're excluded
    if (!shouldInvalidateCache(response.config.url)) {
      const cacheKey = getCacheKey(response.config);
      localStorage.setItem(cacheKey, JSON.stringify(response.data)); // Cache the response
    }
  }
  return response;
};

const cacheResponseErrorInterceptor = (error) => {
  if (error.response && error.response.fromCache) {
    Promise.resolve(error.response);
  }

  return Promise.reject(error);
};

httpClient.interceptors.request.use(authInterceptor);
if (isCacheEnabled) {
  httpClient.interceptors.request.use(cacheRequestInterceptor);
  httpClient.interceptors.response.use(cacheResponseInterceptor, cacheResponseErrorInterceptor);
}
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
